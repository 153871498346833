import React from 'react';
import {Pie} from 'react-chartjs-2';

import Routing from "../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../../../../../js/fos_js_routes.json");
Routing.setRoutingData(routes);

export class PieChartAds extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: ['En vente', 'En pré-vente', 'Réservées', 'Autres'],
            datasets: [
                {
                    label: 'Ads',
                    backgroundColor: [
                        '#4da714',
                        '#1482e7',
                        '#b014e7',
                        '#e79627',
                    ],
                    hoverBackgroundColor: [
                        '#066011',
                        '#105390',
                        '#610772',
                        '#724614',
                    ],
                    data: [this.props.adsInSale, this.props.adsInPreSale, this.props.adsReserved,this.props.adsRestante]
                }
            ]
        }
    }

    render() {
        return (
            <div>
                <Pie
                    data={this.state}
                    options={{
                        legend:{
                            display:true,
                            position:'left',
                        }
                    }}
                />
            </div>
        );
    }
}
