import React from 'react';
import { render } from 'react-dom';
import { ContentExplorer } from "./components/cms/ContentExplorer.jsx";
import { MediaExplorer } from "./components/cms/MediaExplorer.jsx";
import { PageBuilder } from "./components/cms/PageBuilder.jsx";
import { MenuBuilder } from "./components/cms/MenuBuilder.jsx";
import BundleNotificationList from './components/BundleNotificationList';
import { PieChartAds } from './components/dashboard/PieChartAds.jsx'


new ContentExplorer();
new MediaExplorer();
new PageBuilder();
new MenuBuilder();

/**
 * Rendering Notification bundle ReactJS Component
 */
const bundleNotificationContainerElements = Array.prototype.slice.call(document.getElementsByClassName('container-bundle-notification-list-react'));
bundleNotificationContainerElements.forEach(element => {
    render(
        <BundleNotificationList
            bienId={element.dataset.bienId}
            entityClassName={element.dataset.entityClassName}
            entityId={element.dataset.entityId}
            recipientClassName={element.dataset.recipientClassName}
            disablingLinks={element.dataset.disablingLinks}
        />,
        element,
    );
});

// const pieChartAds = document.getElementById("chartPieAdsSale");
// if(pieChartAds) {
//     render(<PieChartAds
//         adsInSale = {pieChartAds.dataset.adsInSale}
//         adsInPreSale = {pieChartAds.dataset.adsInPreSale}
//         adsReserved = {pieChartAds.dataset.adsReserved}
//         adsRestante = {pieChartAds.dataset.adsRestante}
//     />, pieChartAds);
// }
