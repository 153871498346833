import React from "react";

export default class FlashNotification extends React.Component {
    state = {
        notifications: []
    };

    /**
     * Initialise notification
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        let notification = this.props.notification;

        if (notification !== undefined && notification !== null && prevProps.notification !== notification) {
            let notifications = this.state.notifications;

            notifications.push(notification);

            this.setState({
                notifications: notifications
            });
        }
    }

    /**
     * Remove Notification onClick
     *
     * @param index
     */
    removeNotification = (index) => {
        let notifications = [...this.state.notifications];

        notifications.splice(index, 1);

        this.setState({
            notifications: notifications
        });
    }

    render() {
        return (
            <div>
                {this.state.notifications.map((notification, index) => {
                    return (
                        <div key={index} className={notification.type === "success" ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {notification.message}
                            <button type="button" className="close" aria-label="Close" onClick={this.removeNotification.bind(this, index)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }
}
