const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

const dragula = require('dragula');

export class ContentExplorer {
	constructor(){
		this.initContentExplorer();
	}

	initContentExplorer() {
      $('.btn-content-explorer').on('click', function(){
        var pageConfigId = $(this).data('page-config-id');
        var route = Routing.generate('eobs_cms_admin_content_explorer_list_content', { id: pageConfigId });
        $.ajax({
         url : route,
         type : 'GET',
         success : function(response, status){
          $("#contentExplorer").html(response);
          $("#modalContentExplorer").modal();
          ContentExplorer.initContentExplorerContentDelete();
          ContentExplorer.initContentExplorerContentAdd();
          ContentExplorer.initContentExplorerDragAndDrop();
          ContentExplorer.initContentExplorerSearch();
          ContentExplorer.initContentExplorerPagination();
          ContentExplorer.initContentExplorerButtons();

          $('#modalContentExplorer').on('hidden.bs.modal', function (e) {
            document.location.reload();
          });
         }
        });
      });


    }

    static initContentExplorerButtons() {
      $('#btn-content-explorer-auto').on('click', function(){
        $(this).removeClass('btn-default').addClass('btn-success');
        $('#btn-content-explorer-search').removeClass('btn-success').addClass('btn-default');
        $('#content-explorer-card-search').addClass('d-none');
        $('#content-explorer-card-auto').removeClass('d-none');
      });

      $('#btn-content-explorer-search').on('click', function(){
        $(this).removeClass('btn-default').addClass('btn-success');
        $('#btn-content-explorer-auto').removeClass('btn-success').addClass('btn-default');
        $('#content-explorer-card-auto').addClass('d-none');
        $('#content-explorer-card-search').removeClass('d-none');
      });
    }

    static initContentExplorerContentDelete() {
      $('.btn-content-explorer-content-delete').on('click', function(){
        var pageConfigId = $(this).data('page-config-id');
        var contentId = $(this).data('content-id');
        var route = Routing.generate('eobs_cms_admin_content_explorer_content_delete', {
          pageConfigId: pageConfigId,
          contentId: contentId
        });
        $.ajax({
         url : route,
         type : 'GET',
         success : function(response, status){
          $('#contentExplorerSelectedContent'+contentId).remove();
          document.location.reload();
         }
        });
      });
    }

    static initContentExplorerContentAdd() {
      $("#content-explorer-form-add").on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var url = form.attr('action');

        $.ajax({
          type: "POST",
          url: url,
          data: form.serialize(), // serializes the form's elements.
          success: function(data)
          {
             document.location.reload();
          }
        });
      });
    }

    static initContentExplorerDragAndDrop() {

      var contentExplorerDragContainer = document.getElementById('content-explorer-drag-container');

      var drake = dragula([contentExplorerDragContainer], {
        moves: function (el, container, handle) {
          return handle.classList.contains('handle');
        },
      });

      drake.on('drop', function(el, target, source, sibling) {

        var selectedContentIds = [];
        var pageConfigId;

        $(target).find('.content-explorer-content-selected').each(function(){
          selectedContentIds.push($(this).data('content-id'));
          pageConfigId = $(this).data('page-config-id');
        });

        var route = Routing.generate('eobs_cms_admin_content_explorer_content_order');
        $.ajax({
         url : route,
         type : 'POST',
         data: {
          page_config_id: pageConfigId,
          selected_content_ids: selectedContentIds,
         },
         success : function(response, status){

         }
        });

      });
    }

    static initContentExplorerSearch() {
      $("#content-explorer-form-search").on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var url = form.attr('action');

        $.ajax({
          type: "POST",
          url: url,
          data: form.serialize(), // serializes the form's elements.
          success: function(data)
          {
             $('#content-explorer-list').html(data);
             ContentExplorer.initContentExplorerPagination();
          }
        });
      });
    }

    static initContentExplorerPagination() {
      $('#content-explorer-list ul.pagination a').on('click', function(e){
        $.ajax({
            type: "GET",
            url: $(this).attr('href'),
          })
          .done(function(data) {
            $('#content-explorer-list').html(data);
            ContentExplorer.initContentExplorerPagination();
        });
        e.preventDefault();
      });
    }
}
