import React, { Component } from "react";
import { css } from "@emotion/core";
import { FadeLoader } from "react-spinners";
import ReactPaginate from "react-paginate";
import Iframe from "react-iframe";
import FlashNotification from "./FlashNotification";

import Translator from "bazinga-translator";
const translationsConfig = require("../../../../../../js/translations/fr.json");
Translator.fromJSON(translationsConfig);

import Routing from "../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
Routing.setRoutingData(require("../../../../../../js/fos_js_routes.json"));

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

export default class BundleNotificationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingSpinner: false,
            notificationsPerPage: 10,
            notificationsOffsetPage: 0,
            nbTotalNotificationsPage: 1,
            flashMessages: [],
            notifications: [],
            disablingLinks: props.disablingLinks,
            notificationTypes: []
        };
    }

    componentDidMount() {
        this.getNotificationsTypes();
        this.loadNotifications();
    }

    async getNotificationsTypes() {
        this.setState({
            loadingSpinner: true
        });

        let flashMessages = this.state.flashMessages;

        try {
            let url = Routing.generate("notification_api_get_types");

            let response = await fetch(url, {
                method: "GET",
                credentials: "same-origin"
            });

            if (response.status != 200) {
                flashMessages.push({
                    type: "danger",
                    message: "Erreur de chargement"
                });

                this.setState({
                    flashMessage: flashMessages,
                    loadingSpinner: false
                });
            } else {
                let responseData = await response.json();

                if (Array.isArray(responseData.response)) {
                    this.setState(
                        {
                            loadingSpinner: false,
                            notificationTypes: responseData.response
                        },
                        () => {
                            $(".selectpicker").selectpicker("refresh");
                        }
                    );
                }
            }
        } catch (e) {
            flashMessages.push({
                type: "danger",
                message: "Erreur de chargement"
            });

            this.setState({
                flashMessage: flashMessages,
                loadingSpinner: false
            });
        }
    }

    async setNotificationsRead() {
        let notificationsId = "";
        notificationsId = notificationsId.concat(this.state.notifications.map(notif => `${notif.id}`).join(";"));

        let url = Routing.generate("notification_set_read") + "?notificationsId=" + notificationsId;

        let response = await fetch(url, {
            method: "GET",
            credentials: "same-origin"
        });

        if (response.status != 200) {
            let responseData = await response.json();

            let flashMessages = this.state.flashMessages;

            flashMessages.push({
                type: "danger",
                message: responseData.error
            });

            this.setState({
                flashMessage: flashMessages
            });
        } else {
            let element = document.getElementById("icon-layout-notification-message");

            if (element) {
                element.classList.remove("messaging-notify");
                element.removeAttribute("data-badge");
            }
        }
    }

    async loadNotifications(typeNotification = null) {
        this.setState({
            loadingSpinner: true
        });

        let params = {
            limit: this.state.notificationsPerPage,
            offset: this.state.notificationsOffsetPage,
            recipientClassName: this.props.recipientClassName,
            entityClassName: this.props.entityClassName,
            entityId: this.props.entityId,
            type: typeNotification == null ? "" : typeNotification
        };

        let url = Routing.generate("notification_api_get_all", params);

        let response = await fetch(url, {
            method: "GET",
            credentials: "same-origin"
        });

        if (response.status != 200) {
            let responseData = await response.json();

            let flashMessages = this.state.flashMessages;

            flashMessages.push({
                type: "danger",
                message: responseData.error
            });

            this.setState({
                flashMessage: flashMessages,
                loadingSpinner: false
            });
        } else {
            let responseData = await response.json();

            let nbPage = Math.ceil(responseData.nbNotifications / this.state.notificationsPerPage);

            if (!Array.isArray(responseData.notifications)) {
                this.setState(
                    {
                        notifications: Object.values(responseData.fiches),
                        nbTotalNotificationsPage: nbPage,
                        loadingSpinner: false
                    },
                    () => this.setNotificationsRead()
                );
            } else {
                this.setState(
                    {
                        notifications: responseData.notifications,
                        nbTotalNotificationsPage: nbPage,
                        loadingSpinner: false
                    },
                    () => this.setNotificationsRead()
                );
            }
        }
    }

    handleClickPages(page) {
        let offset = Math.ceil(page.selected * this.state.notificationsPerPage);

        this.setState({ notificationsOffsetPage: offset }, () => {
            this.loadNotifications();
        });
    }

    handleSelectNbItem(event) {
        this.setState({ notificationsPerPage: event.target.value }, () => {
            this.loadNotifications();
        });
    }

    onChangeNotificationType(event) {
        let typeFiltering = event.target.value;

        if (typeFiltering == null) {
            this.loadNotifications();
        } else {
            this.loadNotifications(typeFiltering);
        }
    }

    render() {
        let span = <span className="page-link">...</span>;

        let listingNotifications = this.state.notifications.map((notification, index) => {
            return (
                <tr key={index}>
                    <td>{notification.dateNotify}</td>
                    <td>
                        {notification.url === "" || this.state.disablingLinks ? (
                            notification.title
                        ) : (
                            <a href={notification.url}>{notification.title}</a>
                        )}
                    </td>
                    <td>
                        {notification.isSent ? (
                            <span className="badge badge-success">
                                <i className="fas fa-check-circle"></i> {Translator.trans("notification.response.isSent")}
                            </span>
                        ) : (
                            <span className="badge badge-danger">
                                <i className="fas fa-times-circle"></i> {Translator.trans("notification.response.notSent")}
                            </span>
                        )}
                    </td>

                    <td>
                        <div className="dropdown">
                            <button
                                className="btn btn-default dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {Translator.trans("notification.table.actions.messages")}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button data-toggle="modal" data-target={"#notifications-" + notification.id + "-email"} className="dropdown-item">
                                    {Translator.trans("notification.table.actions.email")}
                                </button>
                                <button data-toggle="modal" data-target={"#notifications-" + notification.id + "-sms"} className="dropdown-item">
                                    {Translator.trans("notification.table.actions.sms")}
                                </button>
                                <button data-toggle="modal" data-target={"#notifications-" + notification.id + "-app"} className="dropdown-item">
                                    {Translator.trans("notification.table.actions.app")}
                                </button>
                            </div>
                        </div>

                        <div className="modal fade" id={"notifications-" + notification.id + "-email"} tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="card-title col">{Translator.trans("notification.table.actions.email")}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <strong>{Translator.trans("notification.message.subject")}</strong> :{notification.subjectEmail}
                                        <br />
                                        <strong>{Translator.trans("notification.message.message")}</strong> : <br />
                                        <Iframe url={notification.urlEmailIframe} width="100%" height="500px" display="initial" position="relative" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id={"notifications-" + notification.id + "-sms"} tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="card-title col">{Translator.trans("notification.table.actions.sms")}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">{notification.messageSMS}</div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id={"notifications-" + notification.id + "-app"} tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="card-title col">{Translator.trans("notification.table.actions.app")}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">{notification.messageApp}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });

        let selectTypes = this.state.notificationTypes.map((type, index) => {
            return (
                <option key={index} value={type.key}>
                    {type.trans}
                </option>
            );
        });

        return (
            <div className="card mb-4">
                <FlashNotification notifications={this.state.flashMessages} />

                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-md-6">{Translator.trans("notification.template.title")}</div>
                        <div className="col-md-6">
                            <select onChange={this.onChangeNotificationType.bind(this)} className="selectpicker float-right" data-live-search="true">
                                <option value="">Toutes / Tous</option>

                                {selectTypes}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="card-body p-b-0">
                    <div className="table-responsive">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>{Translator.trans("notification.table.header.date")}</th>
                                    <th>{Translator.trans("notification.table.header.title")}</th>
                                    <th>{Translator.trans("notification.table.header.sent")}</th>
                                    <th>{Translator.trans("notification.table.header.messages")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loadingSpinner ? (
                                    <tr>
                                        <td colSpan="4">
                                            <FadeLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={500}
                                                color={"#004788"}
                                                loading={this.state.loadingSpinner}
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    listingNotifications
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-4">
                            <ReactPaginate
                                breakClassName="page-item"
                                breakLabel={span}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                pageCount={this.state.nbTotalNotificationsPage}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handleClickPages.bind(this)}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                        <div className="col-md-2">
                            <select className="form-control float-right" onChange={this.handleSelectNbItem.bind(this)}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
