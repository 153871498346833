const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

const dragula = require('dragula');

export class MediaExplorer {
	constructor(){
    this.initMediaExplorer();
		this.initMediaExplorerSelect();
	}

  /**
   * Media explorer for page builder
   */
  initMediaExplorer() {
      $('.btn-media-explorer').on('click', function(){
        var pageConfigId = $(this).data('page-config-id');
        var params = { };
        if (pageConfigId != undefined && pageConfigId != '') {
          var params = { id: pageConfigId };
        }
        var route = Routing.generate('eobs_cms_admin_media_explorer_list_media', params);
        $.ajax({
         url : route,
         type : 'GET',
         success : function(response, status){
          $("#mediaExplorer").html(response);
          $("#modalMediaExplorer").modal();
          MediaExplorer.initMediaExplorerMediaDelete();
          MediaExplorer.initMediaExplorerMediaAdd();
          MediaExplorer.initMediaExplorerMediaSelect();
          MediaExplorer.initMediaExplorerDragAndDrop();
          MediaExplorer.initMediaExplorerPagination();

          $('#modalMediaExplorer').on('hidden.bs.modal', function (e) {
            document.location.reload();
          });
         }
        });
      });
    }

    initMediaExplorerSelect() {
      $('.btn-media-explorer-select').on('click', function(){
        var params = { mediaExplorerFieldId: $(this).data('media-explorer-field-id')};
        var route = Routing.generate('eobs_cms_admin_media_explorer_list_media', params);
        $.ajax({
         url : route,
         type : 'GET',
         success : function(response, status){
          $("#mediaExplorer").html(response);
          $("#modalMediaExplorer").modal();
          MediaExplorer.initMediaExplorerMediaSelect();
          MediaExplorer.initMediaExplorerPagination();
         }
        });
      });

      $('.btn-media-explorer-unselect').on('click', function(){
        var fieldId = $(this).data('media-explorer-field-id');
        $('#' + fieldId).val('');
        $('#media_explorer_field_'+fieldId).html('');
        $(this).addClass('d-none');
      });

    }

    static initMediaExplorerMediaDelete() {
      $('.btn-media-explorer-media-delete').on('click', function(){
        var pageConfigId = $(this).data('page-config-id');
        var mediaId = $(this).data('media-id');
        var route = Routing.generate('eobs_cms_admin_media_explorer_media_delete', {
          pageConfigId: pageConfigId,
          mediaId: mediaId
        });
        $.ajax({
         url : route,
         type : 'GET',
         success : function(response, status){
          $('#mediaExplorerSelectedMedia'+mediaId).remove();
          document.location.reload();
         }
        });
      });
    }

    static initMediaExplorerMediaAdd() {
      $("#form-media-explorer-add").on('submit', function(e) {

        e.preventDefault();

        var form = $(this);
        var url = form.attr('action');

        $.ajax({
          type: "POST",
          url: url,
          data: form.serialize(), // serializes the form's elements.
          success: function(data)
          {
             document.location.reload();
          }
        });
      });
    }

    static initMediaExplorerDragAndDrop() {

      var mediaExplorerDragContainer = document.getElementById('media-explorer-drag-container');

      var drake = dragula([mediaExplorerDragContainer], {
        moves: function (el, container, handle) {
          return handle.classList.contains('handle');
        },
      });

      drake.on('drop', function(el, target, source, sibling) {

        var selectedMediaIds = [];
        var pageConfigId;

        $(target).find('.media-explorer-media-selected').each(function(){
          selectedMediaIds.push($(this).data('media-id'));
          pageConfigId = $(this).data('page-config-id');
        });

        var route = Routing.generate('eobs_cms_admin_media_explorer_media_order');
        $.ajax({
         url : route,
         type : 'POST',
         data: {
          page_config_id: pageConfigId,
          selected_media_ids: selectedMediaIds,
         },
         success : function(response, status){

         }
        });

      });
    }

    static initMediaExplorerPagination() {
      $('#media-explorer-list ul.pagination a').on('click', function(e){
        $.ajax({
            type: "GET",
            url: $(this).attr('href'),
          })
          .done(function(data) {
            $('#media-explorer-list').html(data);
            MediaExplorer.initMediaExplorerPagination();
        });
        e.preventDefault();
      });
    }

    static initMediaExplorerMediaSelect() {
      $("#form-media-explorer-select").on('submit', function(e) {
        e.preventDefault();

        var selectedMedia = $('input[name="media"]:checked').val();
        var fieldId = $(this).data('media-explorer-field-id');

        $('#' + fieldId).val(selectedMedia);
        $('#modalMediaExplorer').modal('hide');

        var route = Routing.generate('eobs_cms_admin_media_explorer_field', {id: selectedMedia});
        $.ajax({
         url : route,
         type : 'POST',
         success : function(response, status){
          $('#media_explorer_field_' + fieldId).html(response);
         }
        });

        $('.btn-media-explorer-unselect[data-media-explorer-field-id="' + fieldId + '"]').removeClass('d-none');
      });
    }
}
