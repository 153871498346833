const routes = require('../../../../../../../js/fos_js_routes.json');
import Routing from '../../../../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

const dragula = require('dragula');

import bootbox from 'bootbox';

export class MenuBuilder {
	constructor(){
		this.initMenuItemDragAndDrop();
    this.initChildrenItemsDisplay();
	}

	initMenuItemDragAndDrop() {

    var menuItemDragContainer = document.getElementById('menu-item-drag-container');
    var childrenMenuItemDragContainer = document.getElementById('children-menu-item-drag-container');

    var drake = dragula([menuItemDragContainer, childrenMenuItemDragContainer], {
      moves: function (el, container, handle) {
        return handle.classList.contains('handle');
      },
      accepts: function (el, target, source, sibling) {
        //prevent from droping menu item into same menu item, being parent and child as same time
        if ($(el).attr('data-menu-item-id') == $(target).attr('data-parent-menu-item')) {
          return false;
        }

        return true; // elements can be dropped in any of the `containers` by default
      },

    });

    drake.on('drop', function(el, target, source, sibling) {

      var items = [];

      $(target).find('.menu-item').each(function(){
        items.push($(this).data('menu-item-id'));
      });

      var route = Routing.generate('eobs_cms_admin_menu_item_order');
      $.ajax({
       url : route,
       type : 'POST',
       data: {
        items: items,
        parentMenuItemId: $(target).attr('data-parent-menu-item'),
        droppedMenuItemId: $(el).attr('data-menu-item-id'),
       },
       success: function(response, status){
        MenuBuilder.initTreeDisplay(response.menuId);
        MenuBuilder.displayItem(response.menuItemId);
       }
      });

    });
  }

  initChildrenItemsDisplay() {
    $('.menu-item-parent').on('click', function(){
      $("#children-menu-item-drag-container").attr('data-parent-menu-item', $(this).data('menu-item-id'));
      $('.menu-item-parent').parents('.menu-item').removeClass('active');
      $(this).parents('.menu-item').addClass('active');
      var parentItemId = $(this).data('menu-item-id');
      var route = Routing.generate('eobs_cms_admin_menu_items', {id: parentItemId});
        $.ajax({
         url : route,
         type : 'POST',
         success : function(response, status){
          $("#children-menu-item-drag-container").html(response.content);
          $("#parent-menu-item-title").text(response.title);
          App.initBootBoxModal();
         }
      });
    })
  }

  static initTreeDisplay(menuId) {
    var route = Routing.generate('eobs_cms_admin_menu_tree', {id: menuId});
    $.ajax({
     url : route,
     type : 'POST',
     success : function(response, status){
      $("#tree").html(response);
     }
    });
  }

  static displayItem(menuItemId) {

    var route = Routing.generate('eobs_cms_admin_menu_item', {id: menuItemId});
    $.ajax({
     url : route,
     type : 'POST',
     success : function(response, status){
      $("#menu-item-"+menuItemId).html(response);
     }
    });
  }
}
